.registered-classes-comp{
    width: 100%;
    height: auto;
    max-height: 280px;
    overflow-y: auto;
    box-shadow: 0px 7px 15px #102F9A26;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 50px;
    .reg-cl-top-div{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        color: #0FD9A0;
        border-bottom: 1px solid gainsboro;
        span{
            font: normal normal bold 20px/25px Quicksand;
            color: #071340;
        }
    }
    .reg-cl-list-container{
        .reg-cl-list-item{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding: 20px 0 0;
            .reg-cl-li-div1{
                display: flex;
                justify-content: space-around;
                width: 100%;
                .reg-cl-avatar-div{
                    flex: 1;
                    width: 60px;
                    height: 60px;
                    img{
                        object-fit: cover;
                        width: inherit;
                        height: inherit;
                    }
                }
                .reg-cl-rating-div{
                    p{
                        font: normal normal bold 16px/20px Quicksand;
                        margin: 0;
                        letter-spacing: 0px;
                        color: #071340;
                    }
        
                }
            }
            .reg-cl-li-div2{
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                max-width: 200px;
                p{
                    margin: 0;
                    color: #071340;
                    opacity: 0.7;
                }
                b{
                    color: #071340;
                    opacity: 0.7;
                }
            }
        }
    }
}

@media screen and (min-width: 700px) {
    .registered-classes-comp{
        max-width: 48%;
        margin-bottom: 0;
        .reg-cl-list-container{
            .reg-cl-list-item{
                justify-content: space-between;
                align-items: center;
                .reg-cl-li-div1{
                    flex: 1;
                    max-width: 220px;
                    justify-content: space-between;
                    align-items: center;
                    .reg-cl-rating-div{
                        p{
                            
                        }
                    }
                }
                .reg-cl-li-div2{
                    flex: 1;
                    max-width: 220px;
                    justify-content: space-between;
                    align-items: center;
                }
            }
        }
    }
}

@media screen and (min-width: 900px) {
    .registered-classes-comp{
        max-width: 48%;
        margin-bottom: 0;
        .reg-cl-list-container{
            .reg-cl-list-item{
                justify-content: space-between;
                align-items: center;
                .reg-cl-li-div1{
                    flex: 1;
                    max-width: unset;
                    justify-content: space-between;
                    align-items: center;
                    .reg-cl-rating-div{
                        p{
                            
                        }
                    }
                }
                .reg-cl-li-div2{
                    .reg-cl-div2-child-name{
                        display: flex;
                        flex-wrap: wrap;
                        width: 100%;
                        text-transform: capitalize;
                        p{
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }
}
