.tutors-filter{
    position: relative;
    width: 100%;
    height: 190px;
}
.ttf-form-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 90%;
    margin: auto;
    background-color: white;
    position: absolute;
    top: -50px;
    left: 5%;
    padding: 5px;
    border-radius: 4px;
    box-shadow: 0px 20px 20px #102F9A26;
    form{
        display: flex;
        flex-direction: column;
        .ttf-field-wrapper{
        
        }
    }
}
.ttf-submit-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
    background-color: #0FD9A0;
    border-radius: 4px;
    border: none;
    width: fit-content;
    margin: auto;
    padding: 15px;
}

@media screen and (min-width: 800px) {
    .tutors-filter{
        height: 80px;
    }
    .ttf-form-container{
        flex-direction: row;
        width: 80%;
        padding: 0;
        left: 10%;
        form{
            flex-direction: row;
            flex: 4;
            padding: 10px 0;
            div, div::before{
                border-bottom: none;
            }
            .ttf-field-wrapper{
                flex: 1;
                border-right: 2px solid gainsboro;
                padding: 0 20px;
            }
            .ttf-field-wrapper:last-child{
                border-right: none;
            }
        }
    }
    .ttf-submit-btn{
        flex: 1;
        margin: 0;
        border-radius: 0 4px 4px 0;
    }
    .ttf-submit-btn:hover{
        background-color: #0cad7f;
    }
}