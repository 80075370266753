.classes-page-sec{
    
    h4{
        margin: 20px 0 50px;
        text-align: center;
        font: normal normal bold 16px/20px Quicksand;
        letter-spacing: 4px;
        color: #0FD9A0;
        text-transform: uppercase;
    }
    .classes-found-cont{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}

@media screen and (min-width: 700px) {
    .classes-page-sec{
        width: 90%;
        max-width: 1000px;
        margin: auto;
        h4{
            font: normal normal bold 20px/25px Quicksand;
        }
        .classes-found-cont{
            justify-content: space-between;
        }
    }
    
}

@media screen and (min-width: 1200px) {
    .classes-page-sec{
        
        h4{
            
        }
        .classes-found-cont{
            grid-template-columns: auto auto auto;
            display: grid;
        }
    }
}