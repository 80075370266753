.index-page-container{
    
}
.index-page-intro-sec{
    background: transparent linear-gradient(243deg, #102F9A 0%, #3874E3 100%) 0% 0% no-repeat padding-box;
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.ipi-content{

}
.idp-sec-title-div{
    margin: 15vh 0 0;
    padding: 0 5%;
    width: 100%;
    .idp-sec-title{
        h3{
            margin: 0px;
            color: white;
            font: 24px/32px Quicksand;
        }
        p{
            font: 16px/23px Quicksand;
            font-weight: lighter;
            color: white;
            margin: 0 0 20px;
        }
    }
}
.next-page-pointer{
    position: absolute;
    bottom: -40px;
    left: 0;
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 100px;
    div{
        width: 100%;
    }
    .npp-div1, .npp-div3 {
        flex: 5;
        height: 70%;
        background-color: white;
    }
    .npp-div1{
        border-radius: 0 20px 0 0;
    }
    .npp-div2{
        position: relative;
        height: 55%;
        width: 50px;
        background-color: white;
        div{
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            bottom: 6px;
            left: 0;
            border-radius: 50px;
            background-color: #224ebb;
            height: 100px;
            cursor: pointer;
        }
    }
    .npp-div3{
        border-radius: 20px 0 0 0;
    }
}
.index-page-online-classes-sec{
    height: auto;
    min-height: 500px;
    background-color: #F5F7FD;
    padding: 50px 3%;
    h3{
        text-align: center;
        color: #0FD9A0;
        text-transform: uppercase;
    }
    .idpocs-div1{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 550px;
        margin: 0 auto 30px;
        h3{
            width: 100%;
            font: 20px/25px Quicksand;
            letter-spacing: 4px;
            color: #0FD9A0;
            text-transform: uppercase;
        }
        p{
            text-align: center;
            max-width: 300px;
            font: bold 16px/25px Quicksand;
            color: #071340;
        }
    }
    .idpocs-div2{
        h3{
            margin-bottom: 30px;
        }
        .idpocs-div2-div-cont{
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            .idpocs-card{
                width: 30%;
                min-width: 300px;
                height: 400px;
                box-shadow: 2px 2px 2px 2px gainsboro;
                margin-bottom: 30px;
            }
        }
    }
}
.idp-section3{
    margin-bottom: 50px;
    .idpocs-div2{
        .ipocs-flex-cont{
            display: flex;
            justify-content: space-between;
            .ipoc-fc-item1{
                height: 300px;
                flex: 1;
            }
            .ipoc-fc-item2{
                flex: 1;
                p{
                    margin: 0 0 40px;
                }
            }
        }
    }
}
.idp-section4{
    i{
        max-width: 400px;
        text-align: center;
    }
    p{
        font: bold 18px/23px Quicksand;
        color: #071340;
        opacity: 0.7;
    }
}
.idp-reasons-sec{
    height: auto;
    min-height: 500px;
    background-color: white;
    padding: 50px 5%;
    display: flex;
    flex-direction: column;
    .idp-reasons-intro{
        
    }
    .idp-btb-reasons{
        display: flex;
        flex-wrap: wrap;
    }
}
.bullet-title-body{
    display: flex;
    .idp-bullet{
        background-color: #3874E333;
        color: #3874E3;
        width: 50px;
        min-width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
    }
    .idp-title-body{
        h4{
            margin-top: 0;
        }
    }
}

@media screen and (min-width: 600px) {
    .index-page-intro-sec{
        display: block;
    }
    .index-page-online-classes-sec{
        padding: 50px 10%;
        h3{}
        .idpocs-div1{
            h3{
                margin-top: 60px;
            }
            p{
                max-width: 500px;
            }
        }
        .idpocs-div2{
            .idpocs-div2-div-cont{
                justify-content: space-between;
                .idpocs-card{
                    margin-bottom: 30px;
                }
            }
        }
    }
    .ipi-content{
        padding: 0 calc((100vw - 500px) / 2);
    }
    .idp-sec-title-div{
        margin: 15vh 0 0;
        padding: 0;
        .idp-sec-title{
            flex: 1;
            max-width: 500px;
            h3{
                font: 46px/50px Quicksand;
                margin-bottom: 10px;
            }
            p{
                font: 16px/23px Quicksand;
                margin-bottom: 30px;
            }
        }
    }
    .idp-reasons-sec{
        flex-direction: row;
        .idp-reasons-intro{
            flex: 2;
        }
        .idp-btb-reasons{
            flex: 4;
            justify-content: space-between;
        }
    }
    .bullet-title-body{
        max-width: 350px;
        .idp-bullet{
            
        }
        .idb-title-body{
            
        }
    }
}

@media screen and (min-width: 900px) {
    .ipi-content{
        padding: 0 calc((100vw - 700px) / 2);
    }
    .idp-reasons-sec{
        .idp-reasons-intro{
            flex: 1;
        }
        .idp-btb-reasons{
            flex: 4;
        }
    }
}

@media screen and (min-width: 1200px) {
    .ipi-content{
        padding: 0 calc((100vw - 1100px) / 2);
    }
    .index-page-online-classes-sec{
        h3{}
        .idpocs-div1{
            h3{}
            p{
                max-width: 812px;
            }
        }
        .idpocs-div2{
            .idpocs-card{
                margin-bottom: 0;
            }
        }
    }
    .idp-reasons-sec{
        .idp-reasons-intro{
            margin-right: 150px;
        }
    }
}

@media screen and (min-width: 1400px) {
    .idp-reasons-sec,.index-page-online-classes-sec,.index-page-online-classes-sec,
    .index-page-online-classes-sec,.idp-reasons-sec{
        padding: 0 calc((100vw - 1200px) / 2);
    }
}
