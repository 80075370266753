.steps-indicator{
    height: 40px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    .sti-circle{
        min-width: 40px;
        max-width: 40px;
        height: 40px;
        background-color: #3874E3;
        border: 1px solid #0050E3;
        border-radius: 25px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sti-circle-gray{
        background-color: gainsboro;
        border: 1px solid gainsboro;
    }
    .sti-line{
        border-top: 1.5px solid darkgray;
        width: 100%;
    }
    .sti-line-blue{
        border-top: 1.5px solid #3874E3;
    }
}

@media screen and (min-width: 700px) {
    .steps-indicator{
        .sti-circle{
            height: 50px;
            min-width: 50px
        }
    }
}