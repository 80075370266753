.classes-tile-cont{
    width: 100%;
    max-width: 300px;
    min-height: 450px;
    height: auto;
    box-shadow: 0px 7px 15px #102F9A26;
    border-radius: 4px;
    margin-bottom: 50px;
    .ctc-image-cont{
        width: 100%;
        height: 200px;
        img{
            width: inherit;
            height: inherit;
        }
    }
    .ctc-content-cont{
        padding: 0 25px;
        p{
            font: normal normal bold 20px/38px Quicksand;
            letter-spacing: 0px;
            color: #071340;
            margin-bottom: 0;
        }
        .ctc-rating-div{
            display: flex;
            align-items: center;
            .ctc-avatar{
                width: 40px;
                height: 40px;
                overflow: hidden;
                border-radius: 20px;
                img{
                    width: inherit;
                    height: inherit;
                }
            }
        }
        .ctc-no-of-cls{
            display: flex;
            justify-content: space-between;
            margin: 10px 0 20px;
            span{
                display: flex;
                align-items: center;
                font: normal normal bold 18px/42px Quicksand;
                color: #071340;
            }
        }
        .ctc-last-div{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #0FD9A0;
            .ctc-forward-icon{
                cursor: pointer;
            }
        }
    }
}

@media screen and (min-width: 800px) {
    .classes-tile-cont{
        margin: 0 15px 50px
    }
}

@media screen and (min-width: 1200px) {
    .classes-tile-cont{
        margin: 0 15px 50px;
    }
}