.view-tutor-sec{
    .vtsec-top-block{
        width: 90%;
        margin: 50px auto;
    }
    h4{
        margin: 20px 0 50px;
        text-align: center;
        font: normal normal bold 16px/20px Quicksand;
        letter-spacing: 4px;
        color: #0FD9A0;
        text-transform: uppercase;
    }
    .tblock-classes{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}

@media screen and (min-width: 700px) {
    .view-tutor-sec{
        width: 90%;
        max-width: 1000px;
        margin: auto;
        h4{
            font: normal normal bold 20px/25px Quicksand;
        }
        .tblock-classes{
            justify-content: flex-start;
            margin-bottom: 70px;
        }
    }
}

@media screen and (min-width: 1200px) {
    .view-tutor-sec{
        .vtsec-top-block{
            max-width: 1000px;
            margin: 70px auto;
        }
        h4{
            
        }
    }
}