footer{
    height: auto;
    background: transparent linear-gradient(241deg, #102F9A 0%, #3874E3 100%) 0% 0% no-repeat padding-box;
    padding: 50px 5%;
    color: #FFFFFF;
    .footer-div1{
        .footer-div1-item1{
            margin-bottom: 40px;
            h4{
                font: bold 20px/25px Quicksand;
                letter-spacing: 4px;
                color: #0FD9A0;
                text-transform: uppercase;
                margin: 0 0 20px;
            }
            p{
                font: 400 22px/30px Quicksand;
                color: #FFFFFF;
                margin: 0;
            }
        }
        .footer-div1-item2{
            margin-bottom: 30px;
            p{
                span{
                    color: #FFFFFF;
                    opacity: 0.7;
                }
                a{
                    color: #FFFFFF;
                    text-decoration: underline;
                }
            }
        }
    }
    hr{
        margin-bottom: 30px;
        height: 1px;
        background: white;
        border: none;
    }
    .footer-div2{
        div{
            width: 100%;
            max-width: 295px;
            margin-bottom: 10px;
            h5{
                font: bold 18px/23px Quicksand;
                letter-spacing: 0.9px;
                text-transform: uppercase;
                margin: 0 0 10px;
            }
            ul{
                margin: 0;
                padding: 0;
                list-style: none;
                li{
                    font: 300 14px/20px Quicksand;
                    opacity: 0.8;
                    display: flex;
                    align-items: center;
                    svg{
                        margin-right: 5px;
                    }
                }
            }
            p{
                font: 300 14px/20px Quicksand;
                opacity: 0.8;
                margin-top: 0;
                max-width: 250px;
            }
        }
        .footer-div2-item1{

        }
        .footer-div2-item2{
            
        }
        .footer-div2-item3{
            
        }
        .footer-div2-item4{
            
        }
    }
    .index-copyright{
        margin: 40px 0 0;
    }
}

@media screen and (min-width: 700px) {
    footer{
        .footer-div1{
            display: flex;
            justify-content: space-between;
            .footer-div1-item1{
                h4{
        
                }
                p{
        
                }
            }
            .footer-div1-item2{
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                p{
                    margin-bottom: 0;
                }
            }
        }
        .footer-div2{
            align-items: flex-start;
            div{
                p{
                    max-width: 150px;
                }
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    footer{
        padding: 50px calc((100vw - 1200px) / 2);
        .footer-div1{
            margin-bottom: 30px;
            .footer-div1-item1{
                margin-bottom: 0;
                h4{
        
                }
                p{
        
                }
            }
            .footer-div1-item2{
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 50%;
                max-width: 400px;
                p{
                    margin: 0;
                }
            }
        }
        .footer-div2{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            div{
                p{
                    max-width: 250px;
                }
            }
        }
    }
}
