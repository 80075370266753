.course-arena{
    padding: 24px 2% 0;
    .co-arena-top-div{
        .top-div-div1{
            margin-bottom: 20px;
            h1{
                font: bold 30px/35px Quicksand;
                margin: 0 0 10px;
                color: #0FD9A0;
            }
            p{
                font: bold 16px/20px Quicksand;
                max-width: 400px;
                margin: 0;
                color: #071340;
                opacity: 0.5;
            }
        }
        .top-div-div2{
            width: 100%;
            margin: 30px 0 20px;
            display: flex;
            justify-content: space-between;
            p{
                width: 105px;
                text-align: center;
                font: 400 14px/20px Quicksand;
                color: #071340;
                margin: 0;
                cursor: pointer;
            }
            .tdd-current-tab{
                font: bold 18px/23px Quicksand;
                color: #071340;
                border-bottom: 2px solid #0FD9A0;
                padding-bottom: 10px;
            }
        }
        .top-div-div3{
            p{
                width: 100%;
                text-align: center;
                margin: 0 0 10px;
                font: bold 18px/23px Quicksand;
                color: #071340;
                opacity: 0.6;
            }
            div{
                width: 100%;
                display: flex;
                justify-content: center;
            }
        }
    }
    .co-arena-bottom-div{
        border-top: 1.5px solid gainsboro;
        border-bottom: 1.5px solid gainsboro;
        padding: 5px 0;
        margin-bottom: 30px;
        video, .add-live-lesson{
            width: 100%;
        }
        .co-ar-bt-div-item{
            h3{
                padding: 0 10px;
                background-color: #102F9A26;
                height: 50px;
                margin: 0;
                display: flex;
                align-items: center;
            }
            ul{
                list-style: none;
                padding-left: 0;
                margin: 0;
                .video-tab-playing{
                    background-color: darkblue;
                    color: white;
                    font-size: 18px;
                    text-transform: capitalize;
                }
                li{
                    height: 50px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 10px;
                    background-color: #9A9A9A26;
                    margin-bottom: 2px;
                    cursor: pointer;
                    input{
                        margin-right: 20px;
                    }
                    div{
                        display: flex;
                        align-items: center;
                        width: 85%;
                        overflow-x: auto;
                    }
                    div::-webkit-scrollbar {
                        height: 5px;
                    }
                    p{
                        text-align: start;
                        white-space: nowrap;
                    }
                }
            }
        }
        .co-ar-bt-editor{
            width: 100%;
            min-height: 200px;
            height: auto;
            margin-bottom: 30px;
            border: 1px solid gainsboro;
        }
    }
}

@media screen and (min-width: 700px) {
    .course-arena{
        .co-arena-top-div{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            .top-div-div1{
                width: 70%;
                h1{
                    font: bold 30px/40px Quicksand;
                }
                p{
                    
                }
            }
            .top-div-div2{
                justify-content: flex-start;
                p{
                    width: 150px;
                }
            }
            .top-div-div3{
                width: fit-content;
                p{

                }
                div{

                }
            }
        }
        .co-arena-bottom-div{

        }
    }
}

@media screen and (min-width: 900px) {
    .course-arena{
        .co-arena-top-div{
            margin-bottom: 20px;
            .top-div-div1{
                max-width: 400px;
            }
            .top-div-div2{
                flex: 1;
                margin: 60px 0 0;
                max-width: 600px;
            }
            .top-div-div3{}
        }
        .co-arena-bottom-div{
        
        }
    }
}

@media screen and (min-width: 1200px) {
    .course-arena{
        max-width: 1300px;
        margin: auto;
        .co-arena-top-div{
            margin-bottom: 20px;
            .top-div-div1{
                order: 1;
                max-width: 400px;
            }
            .top-div-div2{
                order: 2;
                flex: 1;
                margin: 60px 0 0;
                max-width: 600px;
            }
            .top-div-div3{
                order: 3;
            }
        }
        .co-arena-bottom-div{
            display: flex;
            align-items: flex-start;
            video, .add-live-lesson{
                flex: 1;
                order: 2;
                margin-left: 5px;
                max-width: calc(75% - 5px);
            }
            .co-ar-bt-div-item{
                width: 100%;
                max-width: 300px;
                min-width: 25%;
                order: 1;
                border-right: 1px solid gainsboro;
                border-left: 1px solid gainsboro;
            }
            .co-ar-bt-editor{
                order: 2;
                margin-left: 20px;
            }
        }
    }
}
