.nav-container{
    background: blue;
    width: 100%;
    z-index: 20;
    top: 0;
    left: 0;
    height: auto;
    padding: 0px 10px 15px;
}
.nav-container.open{
    position: fixed;
}
.nav-container.closed{
    background: transparent;
    height: 70px;
}
.nav-logo{
    width: fit-content;
}
.nav-logo svg{
    width: 150px;
    height: 35px;
}
.nav-control{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    .nav-cancel {
        width: auto;
    }
}
.menu{
    width: 20px;
    height: 16px;
}
.search-cart-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 300px;
    height:70px;
}
.flex-cont.closed{
    display: none;
}
.nav-items{
    margin-top: 0;
    padding: 0;
    list-style: none;
}
.nav-item{
    color: white;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 195.69%;
    padding-left: 10px
}
.current-tab{
    color: white !important;
    border-bottom: 2px solid #0FD9A0;
    padding-left: 0;
    margin-left: 10px;
    margin-bottom: 10px;
    width: fit-content;
}
.nav-buttons{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    a{
        margin-bottom: 20px;
    }
}
.curvy-btn{
    height: 38px;
    border-radius: 50px;
    padding: 6px 7px;
    font-family: Roboto;
    font-style: normal;
    color: #047496;
}
.auth-btn{
    width: 116px;
    border: 2px solid #047496;
    font-size: 14px;
    font-weight: normal;
    line-height: 19px;
    color: #047496;
    background: white;
    margin-bottom: 21px;
}
.download-btn{
    width: 154px;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    background: #FFD809;
    border: none;
}
.white-bg{
    background: white !important;
}

@media screen and (min-width: 360px) {
    .nav-container.closed{
        padding: 0 20px;
    }
}

@media screen and (min-width: 600px) {

}

@media screen and (min-width: 850px) {
    .nav-logo{
        margin-right: 50px;
    }
    .nav-container{
        height: 100px;
        padding: 0 83px;
        display: flex;
        align-items: center;
        background-color: transparent;
    }
    .nav-container.open{
        position: unset;
    }
    .nav-container.closed {
        padding: 2%;
    }
    .nav-content{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }
    .nav-cancel{
        display: none;
    }
    .search-cart-div{
        order: -1;
    }
    .nav-items{
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;
        align-items: center;
        padding: 0 30px 0 20px;
        margin: 0;
        height: fit-content;
    }
    .nav-item{
        margin: 0 20px;
        flex: 5;
    }
    .current-tab{
    }
    .nav-buttons{
        flex-direction: row-reverse;
        justify-content: space-between;
        height: fit-content;
        min-width: 260px;
        margin-top: 0;
        flex: 1;
        a{
            margin-bottom: 0;
        }
    }
    .auth-btn{
        margin: 0;
        margin-right: 18px;
    }
    .nav-control {
        height: fit-content;
        flex: 1;
    }
    .flex-cont{
        width: 70%;
        display: flex;
        align-items: center;
    }
    .flex-cont.closed{
        display: flex;
        flex: 2;
    }
}

@media screen and (min-width: 1300px) {
    .nav-container.closed{
        padding: 0 calc((100vw - 1200px)/2);
    }
}