.learning-plan-page{
    max-width: 1000px;
    padding: 0 5%;
    margin: 50px auto;
    .lpp-top-div{
        padding-bottom: 20px;
        border-bottom: 1px solid gainsboro;
        margin-bottom: 50px;
        h3{
            font: normal normal bold 40px/50px Quicksand;
            color: #0FD9A0;
            margin-bottom: 10px;
        }
        p{
            font: normal normal bold 24px/30px Quicksand;
            letter-spacing: 0px;
            color: #071340;
            opacity: 0.5;
            margin-bottom: 0;
        }
    }
    .lpp-bottom-div{
        display: flex;
        flex-wrap: wrap;
    }
}

@media screen and (min-width: 700px) {
    .learning-plan-page{
        padding: 0;
        .lpp-top-div{
            h3{
                
            }
            p{
                
            }
        }
        .lpp-bottom-div{
            
        }
    }
}