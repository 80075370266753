.upload-classes-page{
    max-width: 1200px;
    margin: auto;
    padding: 30px 2%;
    color: #071340;
    .ucpage-top-div{
        h1{
            font: bold 24px/35px Quicksand;
            margin: 0 0 20px;
        }
        div{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            button:first-child{
                margin-bottom: 20px;
            }
        }
        p{
            margin: 30px 0 50px;
            a{
                display: flex;
                align-items: center;
                cursor: pointer;
                text-decoration: underline;
                font: normal 16px/22px Quicksand;
                color: #071340;
                svg{
                    margin-right: 5px;
                    opacity: 0.5;
                }
            }
        }
    }
    .ucpage-bottom-div{
        box-shadow: 0px 7px 15px #102F9A26;
        border-radius: 4px;
        min-height: 200px;
        height: auto;
        padding: 10px;
        .ucpage-sidebar{
            background-color: #102F9A26;
            width: 100%;
            padding: 10px;
            h3{

            }
            hr{
                border-top: 2px solid #102F9A26;
                border-bottom: none;
                border-radius: 2px;
                margin-bottom: 30px;
            }
            ul{
                padding: 0;
                margin: 0;
                list-style: none;
                li{
                    font: 400 18px/23px Quicksand;
                    color: #071340;
                    width: 168px;
                    height: 44px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    padding-left: 20px;
                }
                .li-on-focus{
                    background-color: #071340;
                    border-radius: 4px;
                    color: #ffffff;
                }
            }
        }
        .ucpage-div2{
            padding: 50px 2%;
            h2{
                margin: 0 0 20px;      
                font: normal normal bold 24px/30px Quicksand;
                letter-spacing: 0px;
            }
            p{
                font: normal normal normal 16px/20px Quicksand;
                opacity: 0.7;
                margin-bottom: 40px;
            }
            hr{
                margin-bottom: 30px;
                border-top: 2px solid gainsboro;
                border-bottom: none;
                border-radius: 2px;
            }
        }
    }
}

@media screen and (min-width: 700px) {
    .upload-classes-page{
        padding: 40px 5%;
        .ucpage-top-div{
            display: flex;
            flex-wrap: wrap;
            h1{
                font: bold 31px/54px Quicksand;
                width: 50%;
                margin: 0;
            }
            div{
                width: 50%;
                display: flex;
                flex-wrap: nowrap;
                justify-content: flex-end;
                align-items: center;
                button:first-child{
                    margin-right: 30px;
                    margin-bottom: 0;
                }
            }
            p{
                font: normal 18px/54px Quicksand;
                margin: 20px 0 50px;
            }
        }
        .ucpage-bottom-div{
            .ucpage-sidebar{
                
            }
            .ucpage-div2{
                padding: 20px 5%;
            }
        }
    }
}

@media screen and (min-width: 900px) {
    .upload-classes-page{
        
        .ucpage-top-div{
            
            h1{
                
            }
            div{
                
                button:first-child{
                    
                }
            }
            p{
                
            }
        }
        .ucpage-bottom-div{
            display: flex;
            padding: 0;
            .ucpage-sidebar{
                flex: 2;
            }
            .ucpage-div2{
                flex: 8;
            }
        }
    }
}

@media screen and (min-width: 1500px) {
    .upload-classes-page{
        max-width: 1200px;
        margin: auto;
    }
}