.open-corp-account {
    width: 100%;
    height: auto;
    min-height: 100vh;
    background-color: #f4f8fc;
    padding-bottom: 50px;
}
.opencorpacclogo {
    padding: 50px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}
.open-corp-account section {
    width: 100%;
    background-color: #fff;
    padding: 20px;
    text-align: center;
    line-height: 25px;
    margin: 0 auto 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 18px;
        letter-spacing: .28px;
        font: 400 14px/18px Quicksand;
    }
    h4{
        color: #1c234f;
    }
}

@media screen and (min-width: 576px) {
    .open-corp-account section {
        width: 80%;
        margin: 50px auto 100px;
    }
}

@media screen and (min-width: 768px) {
    .open-corp-account section {
        width: 70%;
    }
}
