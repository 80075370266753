.onbdg-main{
    margin-bottom: 50px;
}
.prepschool-link{
    color: white !important;
    font-weight: 600;
    text-decoration: none;
}
.onbdg-top-ribbon{
    background: transparent linear-gradient(96deg, #102F9A 0%, #3874E3 100%) 0% 0% no-repeat padding-box;
    height: 200px;
    padding: 50px;
}
.onbdg-form-cont{
    position: relative;
    top: -50px;
    box-shadow: 0px 20px 40px #102F9A26;
    width: 90%;
    margin: auto;
    .onbdg-title{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
        background: transparent linear-gradient(96deg, #102F9A 0%, #3874E3 100%) 0% 0% no-repeat padding-box;
        border-radius: 4px 4px 0px 0px;
        margin-bottom: 40px;
        color: white;
        font: normal normal bold 24px/30px Quicksand;
    }
    form{
        padding: 5%;
        display: flex;
        flex-direction: column;
        align-items: center;
        div{
            width: auto;
        }
        .form-fields{
            width: 100%;
            max-width: 400px;
            margin: auto;
        }
    }
    .submit-btn-div{
        background: #F5F7FD 0% 0% no-repeat padding-box;
        height: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10%;
        p{
            font: normal normal bold 14px/18px Quicksand;
            margin: 0 0 5px;
        }
        a{
            font: normal normal bold 14px/18px Quicksand;
            letter-spacing: 0.13px;
            color: #3874E3;
            text-decoration: none;
        }
    }
}

@media screen and (min-width: 800px) {
    .onbdg-form-bkg{
        padding: 0 10% 0 0;
    }
    .onbdg-form-cont{
        max-width: 500px;
        margin-right: 0;
        .onbdg-title{
            
        }
        form{

        }
        .submit-btn-div{
            
        }
    }
}
