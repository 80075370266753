.tutors-page-sec{
    width: 90%;
    margin: 0 auto;
    padding: 0 0 50px;
    .ttps-search-res{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 50px;
        span{
            margin-bottom: 20px;
        }
        .form-fields{
            min-width: 200px    ;
        }
    }
}

@media screen and (min-width: 700px) {
    .tutors-page-sec{
        width: 80%;
        .ttps-search-res{
            justify-content: space-between;
            span{
                margin-bottom: 0;
            }
            .form-fields{
                
            }
        }
    }
}