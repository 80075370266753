.students-card-comp{
    max-height: 280px;
    .ch-cd-bottom-div{
        justify-content: flex-start;
        overflow-x: auto;
        flex-wrap: nowrap;
        .child-avatar{
            height: 143px;
            width: 120px;
            min-width: unset;
            max-width: unset;
            margin-right: 20px;
            overflow: hidden;
            .ch-av-avatar-div{
                width: 100%;
            }
            .tutor-stud-title-div{
                width: 100%;
                overflow-x: auto;
                p{
                    margin: 5px 0;
                    white-space: nowrap;
                    font-weight: normal;
                }
            }
            .tutor-stud-title-div::-webkit-scrollbar{
                height: 5px;
            }
            .tutor-stud-title-div::-webkit-scrollbar-track {
                background: gainsboro;
                border-radius: 2px;
            }
            .tutor-stud-title-div::-webkit-scrollbar-thumb {
                background-color: darkgray;
                border-radius: 20px;
                border: 3px solid darkgray;
            }
            span{
                margin-top: 5px;
                opacity: 1;
                color: #ced0d9;
                white-space: nowrap;
                align-self: flex-start;
                margin-left: 15px;
            }
            b{
                font-size: 18px;
                color: #071340;
                text-decoration: underline;
            }
        }
    }
}