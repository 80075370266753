.tutor-welcome-page{
    padding: 0 5% 50px;
    h2{
        font: bold 24px/35px Quicksand;
        color: #071340;
        margin-bottom: 70px;
    }
    .twp-flex-cont{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .twp-flex-item{
            box-shadow: 0px 7px 15px #102F9A26;
            border-radius: 4px;
            width: 100%;
            max-width: 380px;
            height: 300px;
            margin-bottom: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            h3{
                font: normal normal bold 31px/54px Quicksand;
                color: #071340;
                margin: 10px 0 20px;
            }
            svg{
                font-size: 100px;
                color: #071340;
                opacity: 0.3;
            }
        }
    }
}

@media screen and (min-width: 700px) {
    .tutor-welcome-page{
        h2{
            font: bold 31px/54px Quicksand;
        }
        .twp-flex-cont{
            max-width: 800px;
            margin: auto;
            .twp-flex-item{
                width: 50%;
            }
        }
    }
}