.tutor-tile-cont{
    width: 100%;
    height: auto;
    min-height: 200px;
    box-shadow: 0px 7px 15px #102F9A26;
    margin-bottom: 50px;
    border-radius: 4px;
    display: flex;
    cursor: pointer;
    .ttc-avatar-div{
        flex: 1;
        min-width: 70px;
        height: inherit;
        padding: 10px 2.5px;
        div{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 65px;
            border-radius: 50px;
            overflow: hidden;
            img{
                object-fit: cover;
                width: inherit;
                height: inherit;
            }
        }
    }
    .ttc-content-div{
        padding: 10px;
        flex: 6;
        .ttc-content-top{
            display: flex;
            flex-wrap: wrap;
            div{
                margin-bottom: 10px;
                p{
                    font: normal normal bold 18px/23px Quicksand;
                    color: #071340;
                    margin: 0;
                }
            }
            .ttc-price{
                font: normal normal bold 24px/30px Quicksand;
                color: #487ADD;
            }
        }
        .ttc-content-mid{
            ul{
                padding-left: 20px;
                color: #487ADD;
                li{
                    span{
                        font: normal normal normal 16px/20px Quicksand;
                        color: #071340;
                        opacity: 0.7;
                    }
                }
            }
        }
        .ttc-content-down{
            p{
                font: normal normal normal 16px/20px Quicksand;
                color: #071340;
                opacity: 0.7;
            }
            .ttc-icon-div{
                width: 120px;
                display: flex;
                justify-content: space-between;
                div{
                    width: 50px;
                    height: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 4px;
                    cursor: pointer;
                }
                div:first-child{
                    color: #0FD9A0;
                    background-color: #0FD9A04D;
                }
                div:last-child{
                    color: #3874E3;
                    background-color: #3874E34D;
                }
            }
        }
    }
}

@media screen and (min-width: 700px) {
    .tutor-tile-cont{
        padding: 20px;
        .ttc-avatar-div{
            flex: 1;
            div{
                width: 97px;
                height: 97px;
            }
        }
        .ttc-content-div{
            flex: 9;
            .ttc-content-top{
                justify-content: space-between;
                div{
                    p{
                        
                    }
                }
                .ttc-price{
                    
                }
            }
            .ttc-content-mid{
                ul{
                    display: flex;
                    flex-wrap: wrap;
                    max-width: 700px;
                    li{
                        margin-right: 30px;
                        margin-bottom: 10px;
                        padding-right: 10px;
                        span{

                        }
                    }
                }
            }
            .ttc-content-down{
                display: flex;
                justify-content: space-between;
                p{
                    margin: 0;
                    flex: 7;
                    max-width: 600px;
                }
                .ttc-icon-div{
                    flex: 2;
                    max-width: 120px;
                    div{
                        
                    }
                    div:first-child{
                        
                    }
                    div:last-child{
                        
                    }
                }
            }
        }
    }
}