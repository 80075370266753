.tutor-block{
    cursor: unset;
    padding: 10px;
    .tblock-proflink-div{
        .proflink{
            text-decoration: underline;
            font: bold 18px/23px Quicksand;
            margin-bottom: 20px !important;
            a{
                color: #487ADD;
            }
        }
    }
}

@media screen and (min-width: 700px) {
    .tutor-block{
        .tblock-proflink-div{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 270px;
            .proflink{
                margin-bottom: 0 !important;
            }
        }
        .tblock-flex-wrapper{
            display: flex;
            align-items: center;
        }
    }
}

@media screen and (min-width: 900px) {
    .tutor-block{
        .tblock-proflink-div{
            
        }
    }
}

@media screen and (min-width: 1200px) {
    .tutor-block{
        .tblock-proflink-div{
            
        }
    }
}
