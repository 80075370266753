.parents-db-page{
    section{
        margin: 25px 5% 70px;
        h4{
            width: 100%;
            text-align: center;
            font: normal normal bold 20px/25px Quicksand;
            letter-spacing: 4px;
            color: #0FD9A0;
            text-transform: uppercase;
            margin-bottom: 50px;
        }
    }
}
.parents-db-sec1{
    .pds-top-cards{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}
.parents-db-sec2{
    min-height: 500px;
    .online-clses-cont{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}
.parents-db-sec3{

}

@media screen and (min-width: 700px) {
    .parents-db-page{
        section{
            margin: 25px 5% 100px;
        }
    }
    .parents-db-sec2, .parents-db-sec3{
        max-width: 1000px;
        margin: auto !important;
    }
    .parents-db-sec2{
        .online-clses-cont{
            max-width: 92%;
            margin: auto;
        }
    }
}


@media screen and (min-width: 1200px) {
    .parents-db-sec2{
        .online-clses-cont{
            max-width: unset;
            margin: auto;
        }
    }
}