.preview-live-class-page{
    .alles-h3{
        margin-bottom: 10px;
        color: #102F9A;
        width: 100%;
        text-align: center;
        text-decoration: underline;
        font-weight: bold;
    }
    .co-arena-bottom-div .co-ar-bt-div-item ul li input{
        margin-right: 5px;
    }
}

@media screen and (min-width: 500px) { 
    .preview-live-class-page{}
}

@media screen and (min-width: 700px) {
    .preview-live-class-page{}
}

@media screen and (min-width: 900px) {
    .preview-live-class-page{}
}

@media screen and (min-width: 1100px) {
    .preview-live-class-page{
        .co-arena-bottom-div{
            .co-ar-bt-div-item{
                max-width: 500px;
                margin-right: 30px;
            }
        }
    }
}