.ucpage-div2{
    .ucpage-div2-video-div{
        padding: 30px 15px;
        background-color: #F5F7FD;
        ul{
            list-style: none;
            padding: 0;
            margin: 0 0 40px;
            li{
                background-color: white;
                display: flex;
                margin-bottom: 10px;
                height: 100px;
                .ucp-div2-title-div{
                    padding: 10px;
                    display: flex;
                    justify-content: flex-end;
                    flex-wrap: wrap;
                    h4{
                        margin: 0;
                        font: bold 18px/22px Quicksand;
                    }
                }
                p{
                    font: 400 14px/20px Quicksand;
                    margin: 0 0;
                }
                img{
                    max-width: 100px;
                    height: inherit;
                }
                svg{
                    opacity: 0.5;
                    width: 18px;
                    cursor: pointer;
                    margin: 0 5px;
                }
                svg:first-child{
                    margin-right: 10px;
                }
            }
        }
    }
    .ucpage-div2-btn-div{
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

@media screen and (min-width: 700px) {
    .ucpage-div2{
        h2{

        }
        p{
            
        }
        hr{
            
        }
        .ucpage-div2-video-div{
            
            ul{
                
                li{
                    
                    .ucp-div2-title-div{
                        width: 100%;
                        justify-content: space-between;
                        h4{
                            
                        }
                    }
                    p{
                        
                    }
                    img{
                        
                    }
                    svg{
                        
                    }
                    svg:first-child{
                        
                    }
                }
            }
        }
        .ucpage-div2-btn-div{
            
        }
    }
}

@media screen and (min-width: 900px) {
    .ucpage-div2{
        h2{

        }
        p{
            
        }
        hr{
            
        }
        .ucpage-div2-video-div{
            
            ul{
                
                li{
                    
                    .ucp-div2-title-div{

                        h4{
                            
                        }
                    }
                    p{
                        
                    }
                    img{
                        
                    }
                    svg{
                        
                    }
                    svg:first-child{
                        
                    }
                }
            }
        }
        .ucpage-div2-btn-div{
            
        }
    }
}