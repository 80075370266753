.tutor-new-bookings-card{
    .booking-tile{
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        .booking-tile-detail{
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 10px;
            div{
                h3{
                    font: bold 18px/18px Quicksand;
                    color: #071340;
                    margin: 0 0 5px;
                }
                span{
                    font: 400 16px/20px Quicksand;
                    color: #071340;
                    opacity: 0.7;
                }
                p{
                    margin: 5px 0;
                }
            }
            .booking-status{
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 10px;
                height: 35px;
                border-radius: 4px;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 1.4px;
                text-transform: capitalize;
            }
            .booking-status1{
                background-color: #cff7ec;
                color: #0FD9A0;
            }
            .booking-status2{
                background-color: #fff5c6;
                color: #FEDC41;
            }
        }
    }
}

@media screen and (min-width: 700px) {

}