.parent-enroll-child{
    padding: 50px 5%;
    .pec-container-div{
        width: 100%;
        min-height: 400px;
        max-width: 700px;
        margin: auto;
        border-radius: 4px;
        overflow: hidden;
        box-shadow: 0px 7px 15px #102F9A26;
        .pec-cont-div1{
            height: 100px;
            background: transparent linear-gradient(270deg, #102F9A 0%, #3874E3 100%) 0% 0% no-repeat padding-box;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #ffffff;
        }
        .pec-cont-div2{
            padding: 50px 2%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            min-height: 250px;
        }
    }
}

@media screen and (min-width: 700px) {
    .parent-enroll-child{
        .pec-container-div{
            
            .pec-cont-div1{

            }
            .pec-cont-div2{
                padding: 50px 15%;
            }
        }
    }
}