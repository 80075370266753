.chidren-card-comp{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    height: auto;
    box-shadow: 0px 7px 15px #102F9A26;
    border-radius: 4px;
    padding: 20px;
    .ch-cd-top-div{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        color: #0FD9A0;
        border-bottom: 1px solid gainsboro;
        span{
            font: normal normal bold 20px/25px Quicksand;
            color: #071340;
        }
    }
    .ch-cd-bottom-div{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex: 1;
        height: auto;
        justify-content: center;
        padding: 20px 0;
        .child-avatar{
            width: 85px;
            min-width: 85px;
            max-width: 85px;
            height: 140px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .ch-av-avatar-div{
                flex: 1;
                width: 80px;
                height: 80px;
                img{
                    object-fit: cover;
                    width: inherit;
                    height: inherit;
                }
            }
            p{
                font: normal normal bold 16px/20px Quicksand;
                letter-spacing: 0px;
                color: #071340;
            }
            span{
                font: normal normal normal 14px/18px Quicksand;
                letter-spacing: 0px;
                color: #071340;
                opacity: 0.7;
            }
        }
    }
}

@media screen and (min-width: 700px) {
    .chidren-card-comp{
        max-width: 48%;
        .ch-cd-bottom-div{
            justify-content: flex-start;
        }
    }
}

@media screen and (min-width: 1200px) {
    .chidren-card-comp{
        .ch-cd-bottom-div{
            flex-wrap: nowrap;
            overflow-x: scroll;
        }
    }
}