.booked-live-course{
    cursor: inherit;
    display: block;
    .blc-img-div {
        box-shadow: 0 0 15px #102f9a26;
        width: 100%;
        height: 252px;
        overflow: hidden;
        img{
            width: inherit;
            height: inherit;
        }
    }
    .blc-detail-div {
        padding: 10px 20px;
        h3{
            font-size: 20px;
            margin-top: 0;
        }
    }
}

@media screen and (min-width: 500px) {
    .booked-live-course{

        .blc-detail-div {
            
        }
    }
}

@media screen and (min-width: 700px) {
    .booked-live-course{
        display: flex;
        .blc-detail-div {
            flex: 7;
            margin-left: 20px
        }
        .blc-img-div {
            flex: 3;
            box-shadow: 0 0 15px #102f9a26;
            width: 100%;
            height: 252px;
            overflow: hidden;
            img{
                width: inherit;
                height: inherit;
            }
        }
    }
    
}

@media screen and (min-width: 900px) {
    .booked-live-course{
        
    }
    
}


@media screen and (min-width: 1200px) {
    .booked-live-course{
        
    }
    
}