.tutor-profile-page{
    .cdp-main-div{
        .cdp-main-top-div{
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 10px;
            h2{
                width: 100%;
                margin: 40px 0 0;
                font: bold 18px/23px Quicksand;
                color: #071340;
            }
            .cdp-rating-div{
                display: block;
                padding-top: 10px;
                h1{
                    font: bold 17px/23px Quicksand;
                    color: #071340;
                }
            }
        }
        .cdp-main-top-div3{
            .cdp-mtd-item{
                margin-bottom: 40px;
                h3{
                    padding-bottom: 5px;
                    margin-bottom: 20px;
                }
            }
            .cdp-mtd-item1{
                ul{
                    display: flex;
                    flex-wrap: wrap;
                    padding: 0;
                    list-style: none;
                    li{
                        width: 100%;
                        display: flex;
                        align-items: center;
                        font: 400 16px/20px Quicksand;
                        color: #525a7a;
                        margin-bottom: 10px;
                        div{
                            width: 7px;
                            height: 7px;
                            border-radius: 4px;
                            background-color: #487ADD;
                            margin-right: 5px;
                            opacity: 1;
                        }
                    }
                }
            }
            .cdp-mtd-item2{

                .cdp-mtd-item2-item{
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 20px;
                    div{
                        
                    }
                    h4{
                        font: bold 16px/20px Quicksand;
                        color: #071340;
                        margin: 0 0 10px;
                    }
                    span{
                        font: 400 16px/20px Quicksand;
                        color: #071340;
                    }
                }
            }
            .cdp-mtd-item3{
                
            }
            .cdp-mtd-item4{
                overflow: auto;
            }
        }
    }
}

@media screen and (min-width: 700px) {
    .tutor-profile-page{
        .cdp-main-div{
            .cdp-main-top-div{
                .cdp-rating-div{
                    h1{
                        
                    }
                }
            }
            .cdp-main-top-div3{
                .cdp-mtd-item1{
                    ul{
                        li{
                            width: 30%;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 900px) {
    .cdp-main-div{
        .cdp-main-top-div{
            .cdp-rating-div{
                h1{
                    
                }
            }
        }
        .cdp-main-top-div3{}
    }
}

@media screen and (min-width: 1200px) {
    .cdp-main-div{
        .cdp-main-top-div{
            .cdp-rating-div{
                h1{
                    
                }
            }
        }
        .cdp-main-top-div3{}
    }
}