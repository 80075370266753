.ucpage-citab-div{
    max-width: 500px;
    // margin: auto;
    label{
        font: bold 18px/30px Quicksand;
    }
}

@media screen and (min-width: 700px) {
    .ucpage-citab-div{
    
    }
}

@media screen and (min-width: 900px) {
    .ucpage-citab-div{
    
    }
}