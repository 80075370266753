.class-detail-page{
    display: flex;
    flex-wrap: wrap;
    max-width: 1100px;
    padding: 0 5%;
    margin: 50px auto;
    .cdp-bk-btn-div{
        width: 100%;
        height: 30px;
        margin-bottom: 30px;
    }
    .cdp-price-div{
        width: 100%;
        margin-bottom: 30px;
        box-shadow: 0px 7px 15px #102F9A26;
        border-radius: 4px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 20px;
        max-height: 150px;
        p{
            margin-top: 0;
            width: 100%;
            text-align: center;
            font: normal normal bold 24px/30px Quicksand;
            letter-spacing: 0px;
            color: #487ADD;
        }
    }
    .cdp-main-div{
        width: 100%;
        min-height: 300px;
        box-shadow: 0px 7px 15px #102F9A26;
        margin-bottom: 30px;
        border-radius: 4px;
        padding: 20px;
        .cdp-main-top-div{
            border-bottom: 1px solid gainsboro;
            padding: 0 0 40px;
            margin-bottom: 30px;
            h1{
                font: normal normal bold 30px/37px Quicksand;
                color: #071340;
                margin: 0;
            }
            .cdp-rating-div{
                display: flex;
                align-items: center;
            }
        }
        .cdp-main-top-div2{
            margin-bottom: 50px;
            video{
                width: 100%;
            }
            p{

            }
        }
        .cdp-main-top-div3{
            h3{
                font: normal normal bold 18px/18px Quicksand;
                color: #071340;
                margin: 0 0 10px;
                padding: 0 0 20px;
                border-bottom: 1px solid gainsboro;
            }
            .lessons-flex-cont{
                .lessons-flex-item{
                    width: 100%;
                    height: 55px;
                    background-color: #9A9A9A26;
                    margin-bottom: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 10px;
                    cursor: pointer;
                }
            }
        }
        .cdp-main-top-div4{
            padding: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

@media screen and (min-width: 700px) {
    .class-detail-page{
        .cdp-bk-btn-div{
            order: 1;
            flex: 1;
            margin-right: 10px;
        }
        .cdp-price-div{
            order: 3;
            flex: 4;
            margin-left: 20px;
        }
        .cdp-main-div{
            order: 2;
            flex: 12;
            .cdp-main-top-div{
                h1{
                    font: normal normal bold 40px/47px Quicksand;
                }
                .cdp-rating-div{
                    
                }
            }
        }
    }
}

@media screen and (min-width: 1000px) {
    .class-detail-page{
        .cdp-bk-btn-div{
            flex: 1;
        }
        .cdp-price-div{
            flex: 3;
        }
        .cdp-main-div{
            flex: 10;
        }
    }
}
