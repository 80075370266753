.tutor-availability-table{
    font: 16px/20px Quicksand;
    table{
        border-collapse: collapse;
    }
    td, th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 7px 8px;
    }
    th{
        //font: Bold 24px/32px Quicksand;
        letter-spacing: 0.48px;
    }
    td{
        //font: 400 20px/27px Quicksand;
        letter-spacing: 0.4px;
    }
}
