html, body{
    width: 100%;
    margin: 0;
}
*{
    box-sizing: border-box;
    font-family: 'Quicksand', sans-serif;
}

.two-col-flex{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.flex-col{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.avatar-div{
    flex: 1;
    width: 60px;
    max-width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    img{
        object-fit: cover;
        width: inherit;
        height: inherit;
    }
}

@media screen and (min-width: 600px) {
    .two-col-flex{
        flex-direction: row;
        justify-content: space-between;
    }
}

@media screen and (min-width: 1300px) {    
    .section-sizing{
        max-width: 1100px;
        margin: auto;
    }
}