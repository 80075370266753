.online-clses-item{
    width: 100%;
    max-width: 300px;
    min-height: 450px;
    height: auto;
    box-shadow: 0px 7px 15px #102F9A26;
    border-radius: 4px;
    margin-bottom: 50px;
    .onl-cls-img{
        width: 100%;
        height: 200px;
        img{
            width: inherit;
            height: inherit;
        }
    }
    .onl-cls-content{
        padding: 20px;
        .oci-rating-div{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            overflow-x: auto;
            p{
                width: 100%;
                margin: 0;
                font: bold 20px/30px Quicksand;
                color: #071340;
                white-space: nowrap;
            }
            .crd-div{
                display: flex;
                align-items: center;
                .ctc-avatar{
                    width: 40px;
                    height: 40px;
                    overflow: hidden;
                    border-radius: 20px;
                    display: flex;
                    img{
                        width: inherit;
                        height: inherit;
                    }
                }
            }
        }
        .oci-rating-div::-webkit-scrollbar {
            height: 5px;
        }
        .oci-mid-p{
            font: normal normal normal 16px/26px Quicksand;
            letter-spacing: 0px;
            color: #071340;
            opacity: 0.7;
        }
    }
}

@media screen and (min-width: 600px) {
    .online-clses-item{
        margin: 0 15px 40px;
    }
}