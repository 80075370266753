.tutors-dashboard{
    padding: 40px 5% 50px;
    h2{
        font: bold 24px/35px Quicksand;
        color: #071340;
        margin-bottom: 70px;
    }
    .twp-flex-cont{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .twp-flex-item{
            box-shadow: 0px 7px 15px #102F9A26;
            border-radius: 4px;
            width: 100%;
            max-width: 380px;
            height: 300px;
            margin-bottom: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            h3{
                font: normal normal bold 31px/54px Quicksand;
                color: #071340;
                margin: 10px 0 20px;
            }
            svg{
                font-size: 100px;
                color: #071340;
                opacity: 0.3;
            }
        }
    }
    .parents-db-page{
        section{
            margin: 25px 5% 70px;
            h4{
                width: 100%;
                text-align: center;
                font: normal normal bold 20px/25px Quicksand;
                letter-spacing: 4px;
                color: #0FD9A0;
                text-transform: uppercase;
                margin-bottom: 50px;
            }
        }
        .parents-db-sec1{
            max-width: 1200px;
            margin: 50px auto;
            .pds-top-cards{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }
        }
        .parents-db-sec2{
            min-height: 500px;
            .online-clses-cont{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            }
        }
        .parents-db-sec3{
        
        }
    }
}

@media screen and (min-width: 700px) {
    .tutors-dashboard{
        h2{
            font: bold 31px/54px Quicksand;
        }
        .twp-flex-cont{
            max-width: 800px;
            margin: auto;
            .twp-flex-item{
                width: 50%;
                max-width: 300px;
            }
        }
        .parents-db-page{
            section{
                margin: 0 5% 50px;
            }
        }
        .parents-db-sec2, .parents-db-sec3{
            max-width: 1000px;
            margin: auto !important;
        }
    }
}

@media screen and (min-width: 900px) {
    .tutors-dashboard{
        h2{
            
        }
        .twp-flex-cont{
            .twp-flex-item{
                max-width: 380px;
            }
        }
        .parents-db-page{
            section{
                
            }
        }
        .parents-db-sec2, .parents-db-sec3{
            
        }
    }
}

@media screen and (min-width: 1200px) {
    .tutors-dashboard{
        .parents-db-page{
            .parents-db-sec1{
                max-width: 1300px;
                margin: 50px auto;
            }
        }
    }
}
