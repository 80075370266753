#category-search-bar{
    .MuiFormControl-root{
        label{
            background-color: white;
            border-radius: 4px;
            padding: 0 7px 0 7px;
        }
        .MuiOutlinedInput-root{
            background-color: white;
        }
    }
}

/*
.search-component{
    width: 100%;
    height: 40px;
    background-color:gainsboro;
    margin-bottom: 10px;
    display: flex;
    border-radius: 4px;
    overflow: hidden;
    select{
        height: 100%;
        flex: 4;
        border: none;
        padding: 0 10px;
        appearance: none;
    }
    select::-ms-expand {
        display: none;
    }
    select:focus{
        border: 0;
        outline: 0;
        box-shadow: 1px 1px gainsboro;
    }
    /*
    input{
        height: 100%;
        flex: 4;
        border: none;
        padding: 0 10px;
    }
    *//*
    span{
        min-width: 90px;
        height: 100%;
        flex: 1;
        background-color: #0FD9A0;
        border: none;
        font: 14px/23px Quicksand;
        color: #FFFFFF;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    span:hover{
        background-color: #0c946e;
    }
}

@media screen and (min-width: 800px) {
    .search-component{
        select{
            flex: 12;
        }
        /*
        input{
            flex: 12;
        }
        *//*
        span{
            text-transform: uppercase;
            flex: 4;
        }
    }
}
*/